import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas"
import { Link, StaticQuery, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import PopUpForm from "../components/common/PopUpForm"
import SVGIcon from "../components/common/SVGIcon"
import CustomAccordion from "../components/common/accordian"
import Frame from "../components/common/frame"
import "../styles/pages/halloween.scss"

const Halloween = () => {
  const [show, setShow] = useState(false)
  const { RiveComponent: HorrorLogo } = useRive({
    src: "https://media.graphassets.com/tCyVP4kjQPmqygECNY00",
    stateMachines: "State Machine",
    onPlay: e => {
      setTimeout(setShow(true), 300)
    },
    onLoadError: e => {
      setShow(true)
      console.log("RiveAnimationError")
    },
    autoplay: true,
    layout: new Layout({
      fit: Fit.Fill,
      alignment: Alignment.TopCenter,
    }),
  })
  const { RiveComponent: PumpkinRive } = useRive({
    src: "https://media.graphassets.com/CgZ0Rvs6Q7qs3EfIjHxx",
    stateMachines: "State Machine",
    autoplay: true,
    layout: new Layout({
      fit: Fit.Fill,
      alignment: Alignment.TopCenter,
    }),
  })
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter a company name",
    },
    {
      name: "phone",
      type: "phone",
      label: "Phone number",
      errorMessage: "Please enter a mobile number",
    },
    {
      name: "horror_story",
      type: "textarea",
      validateEmpty: "true",
      label: "Your horror story",
      errorMessage: "Please enter a valid message",
    },
  ]

  const [storyData, setStoryData] = useState()

  const GET_COUNT = gql`
    query GTCount {
      horrorStories(orderBy: createdAt_DESC) {
        id
        count
        name
        content
      }
    }
  `

  const [GetCount, { loading, error, data }] = useLazyQuery(GET_COUNT, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setStoryData(data?.horrorStories)
    },
  })

  useEffect(() => {
    GetCount()
  }, [])

  const COUNT_MUTATION = gql`
    mutation addCount($id: ID!, $count: Int!) {
      updateHorrorStory(data: { count: $count }, where: { id: $id }) {
        id
        count
      }
    }
  `
  const STAGE_PUBLISH = gql`
    mutation updateStage($id: ID!, $stage: [Stage!]!) {
      publishHorrorStory(where: { id: $id }, to: $stage) {
        id
        name
        stage
        count
        __typename
      }
    }
  `

  const [LikePostFunc, { loading: addLoading, error: addError }] = useMutation(
    COUNT_MUTATION
  )
  const [updateStageFunc, {}] = useMutation(STAGE_PUBLISH)

  const [halloweenLocal, setHalloweenLocal] = useState([])

  useEffect(() => {
    setHalloweenLocal(JSON.parse(localStorage.getItem("halloween_story")))
    if (storyData?.length) {
      let dummy = []
      {
        halloweenLocal?.length
          ? storyData.map(story => {
              let chpt = 1
              JSON.parse(localStorage.getItem("halloween_story")).map(loc => {
                if (story.id === loc.id) {
                  dummy.push({
                    id: story.id,
                    state: loc.state,
                    count: story.count,
                  })
                  chpt = 0
                }
              })

              if (chpt) {
                dummy.push({
                  id: story.id,
                  state: "off",
                  count: story.count,
                })
              }
            })
          : storyData.map(story => {
              dummy.push({
                id: story.id,
                state: "off",
                count: story.count,
              })
            })
      }

      localStorage.setItem("halloween_story", JSON.stringify(dummy))
      setHalloweenLocal(dummy)

      readMoreLess()
    }
  }, [storyData])

  let storyLength = 300

  function readMoreLess() {
    let stories = document.querySelectorAll(".story-content-class")

    stories.length &&
      stories.forEach(story => {
        let content = story.childNodes[0]
        let contentText = content.textContent
        let readMore = story.childNodes[1]

        let contentLength = contentText.length
        if (contentLength > storyLength) {
          content.textContent = contentText.substring(0, storyLength)
          readMore.style.display = "inline"
        }
      })
  }

  function readMore(id) {
    let story = document.getElementById(`story_${id}`)
    storyData.map(item => {
      if (item.id === id) {
        story.childNodes[0].textContent = item.content
        story.childNodes[1].style.display = "none"
        story.childNodes[2].style.display = "inline"
      }
    })
  }

  function readLess(id) {
    let story = document.getElementById(`story_${id}`)
    storyData.map(item => {
      if (item.id === id) {
        story.childNodes[0].textContent = item.content.substring(0, storyLength)
        story.childNodes[1].style.display = "inline"
        story.childNodes[2].style.display = "none"
      }
    })
  }

  function LikeUnLike(id, count) {
    let updatedLikeTracker = [...halloweenLocal]

    halloweenLocal.map((item, ind) => {
      if (item.id === id && item.state === "off") {
        LikePostFunc({
          variables: {
            id: id,
            count: count + 1,
          },
        })
        setTimeout(() => {
          updateStageFunc({
            variables: {
              id: id,
              stage: ["PUBLISHED"],
            },
          })
        }, 3000)

        updatedLikeTracker[ind].state = "on"
        updatedLikeTracker[ind].count = count + 1
      }
    })
    localStorage.setItem("halloween_story", JSON.stringify(updatedLikeTracker))
    setHalloweenLocal(updatedLikeTracker)
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query halloween {
            SuperOps {
              horrorStories {
                id
                name
                content
                count
              }
              pages(where: { title: "Halloween" }) {
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    heroCtaText
                    heroExcerptList
                    animAltText
                  }
                  ... on SuperOps_Card {
                    text
                  }
                  ... on SuperOps_FaqComponent {
                    heading
                    faqs {
                      question
                      answerrt {
                        text
                        html
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField } = page
              const hero = newField[0]
              const card = newField[1]
              const faqComponent = newField[2]
              let delayCount = 0

              let one = [],
                two = [],
                three = [],
                checker = 1

              storyData &&
                storyData.map(item => {
                  if (checker === 1) {
                    one.push(item)
                  } else if (checker === 2) {
                    two.push(item)
                  } else {
                    three.push(item)
                  }
                  checker += 1
                  if (checker === 4) {
                    checker = 1
                  }
                })

              return (
                <div className="halloween-page">
                  <Frame seo={seo} IsFooterVisble="No">
                    <div>
                      <PopUpForm
                        visibility={popupVisibility}
                        togglePopup={val => {
                          setPopupVisibility(val)
                        }}
                        formData={formdata}
                        endpoint={process.env.HUBSPOT_HALLOWEEN_ENDPOINT}
                        url={process.env.HUBSPOT_HALLOWEEN_URL}
                        formName="Superops Community Page - Superops"
                        formTitle="Share your story"
                        formButtonText={"SUBMIT YOUR STORY"}
                        successMsg="Thank you for submitting your story."
                        successMsg2="If another MSP’s horror story gives you the shivers, do upvote it! The story with the most upvotes wins!"
                        additionalFormProps={{ IpStackData: true }}
                        halloween
                      />
                    </div>

                    <section className="hero position-relative">
                      <video
                        className="bg-video d-dsk"
                        autoPlay
                        muted
                        loop
                        id="bg-video"
                      >
                        <source
                          src={
                            "https://media.graphassets.com/TjztjeuTPuWSb2jCBDDk"
                          }
                          type="video/mp4"
                        />
                      </video>
                      <div className="gradient d-dsk"></div>

                      <Container className="Layout-container">
                        <div
                          className="position-relative scary-logo-wrapper mx-auto"
                          style={{ opacity: show ? 0 : 1 }}
                        >
                          <SVGIcon
                            name="halloween/scaryLogo"
                            className=" scary-logo position-absolute"
                          />
                        </div>
                        <div className="horror-logo position-relative mx-auto mb50">
                          <div className="position-absolute mx-auto z-1">
                            <HorrorLogo className="rive-board" />
                          </div>
                        </div>
                        <div style={{ opacity: show ? 1 : 0 }}>
                          <div className="d-flex justify-content-between align-items-center font-white prize-card mx-auto mb50 down-to-up-2">
                            <p className="fw-500 m-0 text">
                              {hero.heroExcerptList[0]}
                            </p>
                            <h1 className="font-roboto fw-bold m-0">
                              {hero.heroExcerptList[1]}
                            </h1>
                          </div>
                          {/* <div className="down-to-up-3">
                            <Buttons
                              theme="btn btn-primary-new halloween-btn d-flex mx-auto"
                              text={hero.heroCtaText}
                              onClick={() => togglePopup()}
                            />
                          </div> */}
                        </div>
                      </Container>
                    </section>

                    <section className="why-story">
                      <Container className="Layout-container position-relative">
                        <SVGIcon
                          name="halloween/decorationLeaves"
                          className="decoration-leaf left position-absolute d-dsk"
                        />
                        <SVGIcon
                          name="halloween/decorationLeaves"
                          className="decoration-leaf right position-absolute d-dsk"
                        />
                        <Col lg={6} className="mx-auto text-center">
                          <h2 className="heading mx-auto font-roboto halloween-orange mb24 down-to-up-1">
                            {card.text[0]}
                          </h2>
                          <p className="font-white mb24 down-to-up-2">
                            {card.text[1]}
                          </p>
                          <div className="halloween-pumpkin mx-auto down-to-up-3 position-relative">
                            <div className="position-absolute mx-auto z-1">
                              <PumpkinRive className="rive-board" />
                            </div>
                          </div>
                          <h2 className="last-heading font-roboto halloween-orange mb24 down-to-up-4">
                            {card.text[2]}
                          </h2>
                        </Col>
                      </Container>
                    </section>

                    <section className="stories mt80">
                      <Container className="Layout-container">
                        {one.length && (
                          <Row>
                            {[one, two, three].map((array, id) => {
                              return (
                                <Col lg={4}>
                                  {array.map(story => {
                                    if (delayCount > 5) {
                                      delayCount = 0
                                    }
                                    return (
                                      <Fade
                                        bottom
                                        delay={(delayCount + 1) * 130}
                                        duration="800"
                                        distance={"20px"}
                                      >
                                        <span className="dspnone">
                                          {delayCount++}
                                        </span>
                                        <Col lg={12}>
                                          <div className="story-box mb24 br10 position-relative">
                                            <div className="d-flex justify-content-between mb16">
                                              <SVGIcon name="halloween/halloweenQuote" />
                                            </div>
                                            <div
                                              id={`story_${story.id}`}
                                              className="story-content-class"
                                            >
                                              <span className="fw-600 content font-white font-roboto mb30">
                                                {story.content}
                                              </span>
                                              <span
                                                className="read-more halloween-orange"
                                                onClick={() =>
                                                  readMore(story.id)
                                                }
                                              >
                                                {" ...read more"}{" "}
                                              </span>
                                              <span
                                                className="read-less halloween-orange"
                                                onClick={() =>
                                                  readLess(story.id)
                                                }
                                              >
                                                ...read less
                                              </span>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-end mt32">
                                              <p className="p24 fw-bold font-roboto name m-0 halloween-orange">
                                                {story.name}
                                              </p>
                                              <div
                                                className={`d-flex align-items-center justify-content-between like-box ${
                                                  halloweenLocal?.length
                                                    ? halloweenLocal.filter(
                                                        x => x.id === story.id
                                                      )[0]?.state
                                                    : "off"
                                                }`}
                                                // onClick={() => {
                                                //   LikeUnLike(
                                                //     story.id,
                                                //     story.count
                                                //   )
                                                // }}
                                              >
                                                <SVGIcon
                                                  name="halloween/heartIn"
                                                  className="heart-in"
                                                />
                                                <p
                                                  className={`m-0 count fw-bold font-roboto`}
                                                >
                                                  {halloweenLocal?.length
                                                    ? halloweenLocal.filter(
                                                        x => x.id === story.id
                                                      )[0]?.count
                                                    : 0}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                      </Fade>
                                    )
                                  })}
                                </Col>
                              )
                            })}
                          </Row>
                        )}
                      </Container>
                    </section>

                    <section className="faq m-0">
                      <Container className="Layout-container">
                        <Row className="justify-content-between">
                          <Col lg={4}>
                            <h2 className="halloween-orange font-roboto mb30">
                              {faqComponent.heading}
                            </h2>
                          </Col>
                          <Col lg={8}>
                            <div className="section">
                              <CustomAccordion items={faqComponent.faqs} />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="halloween-footer">
                      <Container className="Layout-container d-flex align-items-center justify-content-center gap-8 down-to-up-1">
                        <span className="text halloween-orange fw-600 font-roboto p18">
                          Made with
                        </span>
                        <SVGIcon name="halloween/heartIn" />
                        <span className="text halloween-orange fw-600 font-roboto p18">
                          by
                        </span>
                        <Link to="/">
                          <SVGIcon name="logo-light" className="sps-logo" />
                        </Link>
                      </Container>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default Halloween
